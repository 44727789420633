const backHost = "https://messengerback.macrol.lt:4001/";
// const backHost = "https://localhost:4001/";
const backSocketHost = "messengerback.macrol.lt";
// const backSocketHost = "localhost";
const smsServer = "http://localhost:3005/";
module.exports = {
  backHost,
  backSocketHost,
  smsServer,
};
